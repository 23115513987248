import { useEffect, useRef, useState } from "react";
import { getOneCar, lockCar, unlockCar } from "../Services/cars";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Group, Image } from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import "./oneUser.scss";
import { notifications } from "@mantine/notifications";
import { navigationRoute } from "../Router";
import { ICar, IImagesType } from "../types/car";
import { Error, Loading } from "../Components/LoadingAndError";
import { imageCarToArray, withBaseUrlImage } from "../Utils/utils";
import moment from "moment";
import {
  loadingToast,
  successToast,
  toastErrorResponseApi,
} from "../Utils/toast";
import { Id, ToastContainer } from "react-toastify";

function OneCar() {
  const navigate = useNavigate();
  const [currentCar, setCurrentCar] = useState<ICar>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const [carImage, setCarImage] = useState<string[]>([]);
  const toastId = useRef<Id | null>(null);

  useEffect(() => {
    if (id) {
      getCar(id);
    } else {
      navigate(navigationRoute.VOITURES);
    }
  }, [id]);

  useEffect(() => {
    if (currentCar) setCarImage(imageCarToArray(currentCar?.images));
  }, [currentCar]);

  const getCar = (id: any) => {
    setIsLoading(true);
    getOneCar(id)
      .then((res) => {
        setCurrentCar(res.data);
      })
      .catch((error: any) => {
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const lockOneCar = async () => {
    toastId.current = loadingToast(toastId);
    try {
      await lockCar(id);
      toastId.current = successToast(toastId, "La voiture est bloquée.");
      getCar(id);
    } catch (error) {
      toastId.current = toastErrorResponseApi(error, toastId);
    }
  };

  const unlockOneCar = async () => {
    toastId.current = loadingToast(toastId);
    try {
      await unlockCar(id);
      toastId.current = successToast(toastId, "La voiture est débloquée.");
      getCar(id);
    } catch (error) {
      toastId.current = toastErrorResponseApi(error, toastId);
    }
  };

  const Container = (props: any) => {
    return (
      <div key={props.lable} className="container">
        <div className="left">{props.lable}</div>
        <div className="right">{props.value}</div>
      </div>
    );
  };

  return (
    <>
      <ToastContainer limit={3} />
      {isLoading && <Loading />}
      {!isLoading && error && <Error />}
      {!isLoading && !error && (
        <main className="home">
          <ToastContainer limit={3} />
          <section className="layout">
            <Group>
              <Button variant="outline" onClick={() => window.history.back()}>
                {"< Retour "}
              </Button>
              <Button
                color="orange"
                onClick={lockOneCar}
                disabled={currentCar?.status === "blocked"}
              >
                Bloquer la voiture
              </Button>
              <Button
                color="teal"
                onClick={unlockOneCar}
                disabled={currentCar?.status === "active"}
              >
                Débloquer la voiture
              </Button>
            </Group>

            <div className="flex flex-row justify-center w-full my-4">
              <div className="w-[50%]">
                <Carousel
                  withIndicators
                  loop
                  classNames={{
                    root: "aspect-[11/9] w-full",
                    viewport: " w-full h-full",
                    container: " w-full h-full",
                    slide: " w-full",
                    indicator: "h-[3px] bg-white",
                  }}
                >
                  {carImage.map((img) => {
                    return (
                      <Carousel.Slide className="">
                        <img
                          src={withBaseUrlImage(img)}
                          alt=""
                          className="object-cover w-full h-full"
                        />
                      </Carousel.Slide>
                    );
                  })}
                </Carousel>
              </div>
            </div>

            <section className="customTable">
              {currentCar?.images.technical_inspection_image && (
                <Container
                  lable="Carte Grise"
                  value={
                    <Image
                      src={withBaseUrlImage(
                        currentCar.images.technical_inspection_image
                      )}
                      alt="voiture"
                    />
                  }
                />
              )}
              <Container lable="Id" value={currentCar?._id} />
              <Container
                lable="Plaque d'immatriculation"
                value={currentCar?.licensePlate}
              />
              <Container
                lable="Marque et modèle"
                value={
                  currentCar?.brand +
                  " " +
                  currentCar?.model +
                  " " +
                  currentCar?.year
                }
              />
              <Container lable="Type" value={currentCar?.type} />
              <Container lable="Status" value={currentCar?.status} />
              <Container
                lable="Adresse"
                value={
                  currentCar?.address +
                  " " +
                  currentCar?.city +
                  " " +
                  currentCar?.zipCode
                }
              />
              <Container lable="Type de carburant" value={currentCar?.fuel} />
              <Container
                lable="Boîte de vitesses"
                value={currentCar?.gearbox}
              />
              <Container lable="kilométrage" value={currentCar?.mileage} />
              <Container
                lable="Jeune conducteur"
                value={currentCar?.youngDriver ? "Oui" : "Non"}
              />
              <Container
                lable="No. de places"
                value={currentCar?.numberOfPlaces}
              />
              <Container
                lable="No. de portes"
                value={currentCar?.numberOfDoors}
              />
              <Container
                lable="Propriétaire"
                value={
                  <Link to={"/user/" + currentCar?.owner}>
                    {currentCar?.owner}
                  </Link>
                }
              />
              <Container
                lable="Prochain contrôle technique"
                value={
                  currentCar?.nextTechnicalInspection &&
                  moment(new Date(currentCar.nextTechnicalInspection)).format(
                    "DD/MM/YYYY"
                  )
                }
              />
              <Container lable="Description" value={currentCar?.description} />
              <Container
                lable="Options"
                value={
                  <div>
                    {currentCar?.youngDriver && (
                      <p>Jeunes conducteurs autorisés de 0 à 3 ans</p>
                    )}
                    {currentCar?.unlimitedMileage && (
                      <p>Kilométrage illimité inclus</p>
                    )}
                    {currentCar?.airportDelivery && (
                      <p>Livraison aéroport inclus</p>
                    )}
                    {currentCar?.cleanOnReturn && (
                      <p>Nettoyage retour inclus</p>
                    )}
                    {/* {currentCar?.options.map((option: string) => {
                      return <p>{option}</p>
                    })} */}
                  </div>
                }
              />
              <Container
                lable="Équipments"
                value={
                  <div>
                    {currentCar?.equipment.map((equipment: any) => {
                      return <p>{equipment}</p>;
                    })}
                  </div>
                }
              />
              <Container
                lable="Prix par jour"
                value={currentCar?.pricePerDay}
              />
              <Container
                lable="Prix par semaine"
                value={currentCar?.pricePerWeek}
              />
              <Container
                lable="Prix par mois"
                value={currentCar?.pricePerMonth}
              />
            </section>
          </section>
        </main>
      )}
    </>
  );
}

export default OneCar;
