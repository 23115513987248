import { Button, Menu, ScrollArea, Table, rem } from "@mantine/core";
import React from "react";
import { Link } from "react-router-dom";
import { ICancelledRent } from "../../Services/cancelledRent";

interface Props {
  data?: ICancelledRent[];
}
function CancelledRent({ data }: Props) {
  const rows = data?.map((item, index) => {
    const date = item.createdAt
      ? item.createdAt
      : item.created_at
      ? item.created_at
      : "";

    let showDate = "";
    if (date !== "") {
      showDate = new Date(date).toLocaleDateString();
    }
    return (
      <tr key={item._id}>
        <td>{index + 1}</td>
        <td>{item.reason}</td>
        <td>
          <Link
            to={`/rent/${item.rentId}`}
            replace
            style={{ textDecoration: "none", color: "#C1C2C5" }}
          >
            {item.rentId}
          </Link>
        </td>
        <td>{showDate}</td>
        <td>{item.status}</td>
      </tr>
    );
  });
  return (
    <ScrollArea>
      <Table>
        <thead>
          <tr>
            <th style={{ width: rem(40) }}>No.</th>
            <th>Raison</th>
            <th>No. rent</th>
            <th>Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </ScrollArea>
  );
}

export default CancelledRent;
