import "./styles.scss";

export const Loading = () => {
  return (
    <div className="loading">
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      Chargement...
    </div>
  );
};

export const Error = () => {
  return <div className="error">Un problème s'est produit, veuillez réessayer</div>;
};
