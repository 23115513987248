const api = {
  rent: {
    getAllRent: "office/getAllRent",
    cancelledRent: "cancelled-rent",
    getRentById: "rent/rentDetail",
  },
  user: {
    getAllUser: "office/searchUser",
    getUserById: "office/getUser",
    updateUserState: "office/user-state",
    allUserPro: "office/AllProUser",
    getCommissionByUserId: (userId: string) =>
      `office/getProCommission/${userId}`,
    setCommissionPro: "office/setProCommission",
    updateProCommission: (commissionId: string, commission: number) =>
      `office/UpdateProCommission/${commissionId}/${commission}`,
    updateCommission: (userId: string, commission: number) =>
      `office/updateCommission/${userId}/${commission}`,
  },
  help: {
    createSection: "centre-aide/create-section",
    updateSection: "centre-aide/update-section",
    deleteSection: "centre-aide/delete-section",
    createArticle: "centre-aide/create-article",
    updateArticle: "centre-aide/update-article",
    deleteArticle: "centre-aide/delete-article",
    getSectionList: "centre-aide/section-list",
    getVisibilityList: "centre-aide/visibility-list",
  },
  car: {
    getAllCars: "office/getAllCars",
    getOneCar: "office/getCar",
    blockCar: "office/blockCar",
    unblockCar: "office/unblockCar",
  },
  parametrage: {
    commission: {
      getCommissionPro: "office/getProCommission",
      editCommssionPro: "office/setProCommission",
    },
  },
  report: {
    getDetailReport: "office/getReport",
    sinistre: {
      getAllSinistre: "office/getAllSinistreReport",
    },
    bugs: "office/bugs",
    car: "office/getAllCarReport",
    profile: "office/getAllProfileReport",
    markAsSeen: "office/markReportAsSeen",
  },
};

export default api;
