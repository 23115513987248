import { useEffect, useRef, useState } from "react";
import {
  getUserInfo,
  lockUser,
  unlockUser,
  validateUser,
  refuseUser,
  getUserById,
} from "../Services/user";
import { useParams } from "react-router-dom";
import "./oneUser.scss";
import { Group, Button } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IUser } from "../types/user";
import moment from "moment";
import { useQuery } from "react-query";
import { Error, Loading } from "../Components/LoadingAndError";
import { ICar } from "../types/car";
import {
  loadingToast,
  successToast,
  toastErrorResponseApi,
} from "../Utils/toast";
import { Id, ToastContainer } from "react-toastify";
import { imagePicture } from "../Utils/utils";

function UserPage() {
  const [user, setUser] = useState<IUser>();
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<any | null>(null);
  const toastId = useRef<Id | null>(null);

  const getUser = (id: any) => {
    setIsLoading(true);
    getUserById(id)
      .then((res) => {
        setUser(res.data);
      })
      .catch((error: any) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getUser(id);
  }, [id]);

  const lockOneUser = async () => {
    toastId.current = loadingToast(toastId);
    try {
      if (id) {
        await lockUser(id);
        getUser(id);
        toastId.current = successToast(toastId, "Utilisateur banni.");
      }
    } catch (error) {
      toastId.current = toastErrorResponseApi(error, toastId);
    }
  };

  const unlockOneUser = async () => {
    toastId.current = loadingToast(toastId);
    try {
      if (id) {
        await unlockUser(id);
        getUser(id);
        toastId.current = successToast(toastId, "Utilisateur ébloquée.");
      }
    } catch (error) {
      toastId.current = toastErrorResponseApi(error, toastId);
    }
  };

  const Container = (props: any) => {
    return (
      <div key={props.lable} className="container">
        <div className="left">{props.lable}</div>
        <div className="right">{props.value}</div>
      </div>
    );
  };

  if (isLoading) return <Loading />;
  if (error) return <Error />;

  return (
    <main className="home">
      <ToastContainer limit={3} />
      <section className="layout">
        <Group>
          <Button variant="outline" onClick={() => window.history.back()}>
            {"< Retour "}
          </Button>
          <Button onClick={lockOneUser} color="yellow">
            Bloquer l'utilisateur
          </Button>
          <Button onClick={unlockOneUser} color="teal">
            Débloquer l'utilisateur
          </Button>
        </Group>
        <section className="customTable">
          <Container lable="Id" value={user?._id} />
          {/* <Container lable="Role" value={user?.role} /> */}
          <Container lable="Type de compte" value={user?.accountType} />
          <Container
            lable="Nom et prénom"
            value={user?.firstname + " " + user?.lastname}
          />
          {user?.isBlocked ? (
            <Container lable="Status" value="Bloquée" />
          ) : (
            <Container lable="Status" value={user?.status} />
          )}
          <Container
            lable="Utilisateur Verifié"
            value={user?.isUserValidated ? "Oui" : "Non"}
          />

          <Container
            lable="Informations bancaires"
            value={user?.isBankInfoComplete}
          />
          <Container
            lable="Date d'inscription"
            value={
              user?.registrationDate &&
              moment(new Date(user.registrationDate)).format("DD/MM/YYYY")
            }
          />
          <Container
            lable="Address"
            value={
              user?.adress &&
              user.adress.address +
                " " +
                user.adress.city +
                " " +
                user.adress.zipCode
            }
          />
          <Container
            lable="Date de naissance"
            value={
              user?.dayOfBirth &&
              moment(new Date(user?.dayOfBirth)).format("DD/MM/YYYY") +
                "  à  " +
                user.birthplace
            }
          />
          <Container
            lable="Phone"
            value={<a href={"tel:" + user?.phone}>{user?.phone}</a>}
          />
          <Container
            lable="Email"
            value={<a href={"mailto:" + user?.email}>{user?.email}</a>}
          />
          <Container
            lable="Photo de profil"
            value={
              <img
                src={imagePicture(user?.picture)}
                alt="Aucune photo de profil."
                className="rounded-full object-cover"
              />
            }
          />

          <Container
            lable="Voitures"
            value={
              user?.car &&
              user?.car.map((car: string) => {
                return <a href={"/car/" + car}>{car}</a>;
              })
            }
          />

          <Container
            lable="Est-elle supprimée"
            value={user?.deleted ? "Oui" : "Non"}
          />
          <Container
            lable="Informations bancaires complètes"
            value={user?.isBankInfoComplete}
          />
          {/* <Container
            lable="Stripe Lien de vérification"
            value={user?.stripeVerificationLink}
          /> */}
          <Container lable="Description" value={user?.description} />
          <Container
            lable="Voiture préférée"
            value={
              user?.favoriteCar &&
              user?.favoriteCar.map((car: string) => {
                return <a href={"/car/" + car}>{car}</a>;
              })
            }
          />
          <Container
            lable="Code de validation email"
            value={user?.validedCode}
          />
          <Container
            lable="Code mot de passe oublié"
            value={user?.forgotValidedCode}
          />
        </section>
      </section>
    </main>
  );
}

export default UserPage;
