import React from "react";
import CancelledRent from "../Components/CancelledRent";
import { useQuery } from "react-query";
import { getCancelledRent } from "../Services/cancelledRent";
import { Error, Loading } from "../Components/LoadingAndError";

function CancelledRentPage() {
  const { data, error, isLoading } = useQuery(["annulation"], () =>
    getCancelledRent().then((res) => res.data)
  );
  if (isLoading) return <Loading />;
  if (error) return <Error />;
  return (
    <main className="home">
      <section className="layout">
        <CancelledRent data={data} />
      </section>
    </main>
  );
}

export default CancelledRentPage;
