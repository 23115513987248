import { useQuery } from "react-query";
import "./App.css";
import { StatsGroup } from "./Components/Status";
import { getStatus } from "./Services/status";
import { Loading, Error } from "./Components/LoadingAndError";
import { StorageUtils } from "./Utils/StorageUtils";

function Home() {
  const token = StorageUtils.getData("access-token");

  const { data, error, isLoading } = useQuery(["status"], () =>
    getStatus().then((res: any) => {
      return res;
    })
  );

  if (isLoading) return <Loading />;
  if (error) return <Error />;

  return (
    <main className="home">
      <section className="layout">
        <StatsGroup
          data={[
            {
              title: "Utilisateurs",
              stats: data.users,
            },
            {
              title: "Reservations",
              stats: data.rents,
            },
            {
              title: "Voitures",
              stats: data.cars,
            },
            {
              title: "Signalements",
              stats: data.reports,
            },
          ]}
        />
      </section>
    </main>
  );
}

export default Home;
