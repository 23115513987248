import axiosInstance from "../axios/axiosInstance";
import api from "../endpoint";

export interface ICancelledRent {
  _id: string;
  reason: string;
  rentId: string;
  created_at?: string;
  createdAt?: string;
  status: string;
}

export const getCancelledRent = () => {
  return axiosInstance.get<ICancelledRent[]>(api.rent.cancelledRent);
};
