export const withBaseUrlImage = (image: string) => {
  return `${process.env.REACT_APP_BASE_API_URL}images/${image}`;
};

export const imagePicture = (image?: string) => {
  let picture = image || "defaultImage.png";
  if (image === "" || image === "default.png") {
    picture = "defaultImage.png";
  }

  if (picture.includes("http")) {
    return picture;
  } else return withBaseUrlImage(picture);
};

export const imageCarToArray = (images: any) => {
  let list = [];

  if (images.avant34) list.push(images.avant34);
  if (images.ariere34) list.push(images.ariere34);
  if (images.interieur) list.push(images.interieur);
  if (images.lateral) list.push(images.lateral);
  if (images.supplementaire) {
    let imageSup = images.supplementaire;
    imageSup.forEach((element: string) => {
      if (element !== "") list.push(element);
    });
  }
  return list;
};
