import Filter from "../../types/Filter";
import ApiResponse from "../../types/apiResponse";
import { IRent, IRentDetail } from "../../types/rent";
import axiosInstance from "../axios/axiosInstance";
import api from "../endpoint";
import request from "../request";

export interface DataRentResponse extends ApiResponse {
  rents: IRent[];
}

export const getReservations = () => {
  return request({
    url: "office/getAllRent?documentCount=1&pageNumber=1",
    method: "GET",
  });
};

//get one car
export const getOneReservation = (id: any) => {
  return request({
    url: `office/getInfoById?rentId=${id}`,
    method: "GET",
  });
};

export const getListReservation = (filtre?: Filter) => {
  let filter: Filter = {
    documentCount: filtre?.documentCount || 1000000,
    pageNumber: filtre?.pageNumber || 1,
  };
  return axiosInstance.get<DataRentResponse>(api.rent.getAllRent, {
    params: {
      ...filter,
    },
  });
};

export const getReservationDetail = (id: any) => {
  return axiosInstance.get<IRentDetail>(`${api.rent.getRentById}/${id}`);
};
