import { Table, ScrollArea, rem, Button, Menu, Select } from "@mantine/core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { IRent } from "../../types/rent";
import { Loading } from "../LoadingAndError";
import { findSubstring } from "../../Utils/fonctionRecherche";
moment.locale("fr");

interface TableProps {
  data: IRent[];
}

export function ReservationsTable({ data }: TableProps) {
  const navigate = useNavigate();
  const [statusFilter, setStatusFilter] = useState("all");

  const [searchValue, setSearchValue] = useState("");

  const [listRent, setlistRent] = useState<IRent[]>(data);

  const [loadingSearch, setLoadingSearch] = useState(false);

  const filtreData = (status: string) => {
    const listFilter = data.filter((item) => item.status === status);
    return listFilter;
  };

  useEffect(() => {
    // setLoadingSearch(true);
    // setlistRent([]);
    let list = [];
    if (statusFilter === "all") {
      list = data;
    } else {
      list = filtreData(statusFilter);
    }
    setlistRent(list);
    // setLoadingSearch(false);
  }, [statusFilter, data, searchValue]);

  const rows = listRent.map((item, index) => {
    return (
      <tr key={item._id}>
        <td>{index + 1}</td>
        <td>{item.customer}</td>
        <td>{item.status}</td>
        <td>
          {moment(new Date(item.date.startDay)).format("DD/MM/YYYY")} à{" "}
          {item.date.startTime}
        </td>
        <td>
          {moment(new Date(item.date.endDay)).format("DD/MM/YYYY")} à{" "}
          {item.date.endTime}
        </td>
        <td>
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button
                onClick={() => {
                  navigate(`/Reservations/${item._id}`);
                }}
              >
                Voir
              </Button>
            </Menu.Target>
          </Menu>
        </td>
      </tr>
    );
  });

  const handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    setlistRent([]);
  };

  return (
    <>
      {/* <div className="mb-4">
        <input
          type="text"
          placeholder="Recherche"
          value={searchValue}
          onChange={handleSearchValueChange}
          className={`p-1 px-4 w-full box-border border rounded-[4px] bg-[#00000000] font-[300]
            placeholder:text-textGrey focus:ring-0 focus:border-[#6b7280]`}
        />
      </div> */}
      <Select
        radius={"md"}
        searchable
        placeholder={statusFilter}
        value={statusFilter}
        data={[
          { value: "all", label: "all" },
          { value: "in-progress", label: "in-progress" },
          { value: "cancelled", label: "cancelled" },
          { value: "pending", label: "pending" },
          { value: "rejected", label: "rejected" },
          { value: "finished", label: "finished" },
        ]}
        onChange={(value: string) => {
          setStatusFilter(value);
        }}
      />
      {loadingSearch ? (
        <Loading />
      ) : (
        <ScrollArea>
          <Table miw={800} verticalSpacing="sm">
            <thead>
              <tr>
                <th style={{ width: rem(40) }}>No.</th>
                <th>Customer</th>
                <th>Status</th>
                <th>Start date</th>
                <th>End Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </ScrollArea>
      )}
    </>
  );
}
