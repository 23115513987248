import React from "react";
import Helps from "../Components/Helps";

function HelpPage() {
  return (
    <main className="home">
      <section className="layout">
        <Helps />
      </section>
    </main>
  );
}

export default HelpPage;
