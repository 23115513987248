import {
  TextInput,
  PasswordInput,
  Paper,
  Title,
  Container,
  Button,
} from "@mantine/core";

import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { login } from "../../Services/auth";
import { StorageUtils } from "../../Utils/StorageUtils";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export function Authentication() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    if (StorageUtils.getData("access-token")) {
      navigate("/Status");
    }
  }, [navigate]);

  const Connexion = async (e: any) => {
    const data = {
      email: email,
      password: password,
    };
    try {
      e.preventDefault();

      const response: any = await login(data);
      StorageUtils.saveData("access-token", response.access_token);
      StorageUtils.saveData("refresh-token", response.refresh_token);
      window.location.replace("/Status");
    } catch (error: any) {
      const message = error?.response?.data?.message || "Erreur de connexion";
      toast(message);
    }
  };

  return (
    <Container size={420} my={100}>
      <ToastContainer limit={3} />
      <Title
        align="center"
        sx={(theme) => ({
          fontFamily: `Greycliff CF, ${theme.fontFamily}`,
          fontWeight: 900,
        })}
      >
        Gando Admin
      </Title>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <TextInput
          label="Email"
          placeholder="you@mantine.dev"
          required
          onChange={(e) => setEmail(e.target.value)}
        />
        <PasswordInput
          label="Password"
          placeholder="Your password"
          required
          mt="md"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />

        <Button fullWidth mt="xl" onClick={Connexion}>
          Connexion
        </Button>
      </Paper>
    </Container>
  );
}
