import axios from "axios";
import { StorageUtils } from "../../Utils/StorageUtils";
import { CurrentUser } from "../../Utils/Currentuser";
import { toast } from "react-toastify";

// create an axios instance
const service = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API_URL}v1/`,
  timeout: 60000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

// request interceptor
service.interceptors.request.use(
  (config: any) => {
    const token = StorageUtils.getData("access-token");
    if (token) {
      config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
    }
    return config;
  },
  (error) => {
    // gerer les erreurs de la requete
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    if (response.status !== 200) {
      return Promise.reject(new Error("Error"));
    } else {
      return response.data;
    }
  },
  async (error) => {
    if (error.response?.status === 401) {
      const prevRequest = error.config;

      const refreshToken = StorageUtils.getData("refresh-token");
      if (refreshToken && !prevRequest.sent) {
        prevRequest.sent = true;

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_API_URL}v1/auth/refresh`,
            null,
            {
              headers: {
                Authorization: `Bearer ${refreshToken}`,
              },
            }
          );

          const newAccessToken = response.data.access_token;
          const newRefreshToken = response.data.refresh_token;

          StorageUtils.saveData("access-token", newAccessToken);
          StorageUtils.saveData("refresh-token", newRefreshToken);
          error.config.headers.Authorization = `Bearer ${newAccessToken}`;

          return service.request(error.config);
        } catch (error) {
          console.log(
            "==================*********************************=================="
          );
          console.log("refresh token expired ou invalid");
          console.log(
            "==================*********************************=================="
          );
          toast.error("Accès non autorisé.", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            pauseOnFocusLoss: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          return CurrentUser.logout();
        }
      } else {
        toast.error("Accès non autorisé.", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          pauseOnFocusLoss: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return CurrentUser.logout();
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export default service;
