import { Routes, Route, Navigate } from "react-router-dom";
import { Authentication } from "./Components/Login";
import Home from "./Home";
import { CurrentUser } from "./Utils/Currentuser";
import { HeaderResponsive } from "./Components/Header";
import UserPage from "./Pages/UserPage";
import OneUser from "./Pages/oneUser";
import CarsPage from "./Pages/CarsPage";
import BugsPage from "./Pages/BugsPage";
import OneCar from "./Pages/oneCar";
import ReservationPage from "./Pages/ReservationPage";
import OneReservation from "./Pages/oneReservation";
import HelpPage from "./Pages/HelpPage";
import LocationPage from "./Pages/LocationPage";
import OneSinister from "./Pages/OneSinister";
import CancelledRentPage from "./Pages/CancelledRentPage";
import Parametrage from "./Pages/Parametrage";
import CommissionProScreen from "./Pages/CommissionPro";

export enum navigationRoute {
  STATUS = "/Status",
  UTILISATEURS = "/Utilisateurs",
  RESERVATION = "/Reservations",
  VOITURES = "/Voitures",
  SIGNALEMENTS = "/Signalements",
  AIDES = "/Aides",
  SINISTRES = "/Sinistres",
  CANCELLED_RENT = "/CancelledRent",
  PARAMETRAGE = "/Parametrage",
  COMMISSION_PRO = "/Commission-Pro",
}

const Router = () => {
  return (
    <>
      <HeaderResponsive
        links={[
          { label: "Status", link: navigationRoute.STATUS },
          { label: "Utilisateurs", link: navigationRoute.UTILISATEURS },
          { label: "Reservations", link: navigationRoute.RESERVATION },
          { label: "Voitures", link: navigationRoute.VOITURES },
          { label: "Signalements", link: navigationRoute.SIGNALEMENTS },
          { label: "Aides", link: navigationRoute.AIDES },
          { label: "Sinistres", link: navigationRoute.SINISTRES },
          {
            label: "Annulations locations",
            link: navigationRoute.CANCELLED_RENT,
          },
          // { label: "Parametrage", link: navigationRoute.PARAMETRAGE },
          { label: "Commission Pro", link: navigationRoute.COMMISSION_PRO },
        ]}
      />

      <Routes>
        <Route
          path="/user/:id"
          element={
            CurrentUser.isLoged() ? <OneUser /> : <Navigate replace to="/" />
          }
        />

        <Route
          path="/Reservations/:id"
          element={
            CurrentUser.isLoged() ? (
              <OneReservation />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />

        <Route
          path="/car/:id"
          element={
            CurrentUser.isLoged() ? <OneCar /> : <Navigate replace to="/" />
          }
        />
        <Route
          path="/sinister/:id"
          element={
            CurrentUser.isLoged() ? (
              <OneSinister />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />

        <Route
          path={navigationRoute.STATUS}
          element={
            CurrentUser.isLoged() ? <Home /> : <Navigate replace to="/" />
          }
        />

        <Route
          path={navigationRoute.UTILISATEURS}
          element={
            CurrentUser.isLoged() ? <UserPage /> : <Navigate replace to="/" />
          }
        />

        <Route
          path={navigationRoute.VOITURES}
          element={
            CurrentUser.isLoged() ? <CarsPage /> : <Navigate replace to="/" />
          }
        />

        <Route
          path={navigationRoute.RESERVATION}
          element={
            CurrentUser.isLoged() ? (
              <ReservationPage />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />

        <Route
          path={navigationRoute.SIGNALEMENTS}
          element={
            CurrentUser.isLoged() ? <BugsPage /> : <Navigate replace to="/" />
          }
        />

        <Route
          path={navigationRoute.AIDES}
          element={
            CurrentUser.isLoged() ? <HelpPage /> : <Navigate replace to={"/"} />
          }
        />

        <Route
          path={navigationRoute.SINISTRES}
          element={
            CurrentUser.isLoged() ? (
              <LocationPage />
            ) : (
              <Navigate replace to={"/"} />
            )
          }
        />

        <Route
          path={navigationRoute.CANCELLED_RENT}
          element={
            CurrentUser.isLoged() ? (
              <CancelledRentPage />
            ) : (
              <Navigate replace to={"/"} />
            )
          }
        />

        {/* <Route
          path={navigationRoute.PARAMETRAGE}
          element={
            CurrentUser.isLoged() ? (
              <Parametrage />
            ) : (
              <Navigate replace to="/" />
            )
          }
        /> */}

        <Route
          path={navigationRoute.COMMISSION_PRO}
          element={
            CurrentUser.isLoged() ? (
              <CommissionProScreen />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route path="/" element={<Authentication />} />
        <Route
          path="/*"
          element={
            <div className="w-full flex flex-row justify-center mt-[200px] text-2xl font-[600]">
              Page introuvable
            </div>
          }
        />
      </Routes>
    </>
  );
};

export default Router;
