import Filter from "../../types/Filter";
import ApiResponse from "../../types/apiResponse";
import { ISinistre } from "../../types/sinistre";
import axiosInstance from "../axios/axiosInstance";
import api from "../endpoint";
import request from "../request";

interface DataSinistreResponse extends ApiResponse {
  reports: ISinistre[];
}

export const getSinisters = (filtre?: Filter) => {
  let filter: Filter = {
    documentCount: filtre?.documentCount || 10000000,
    pageNumber: filtre?.pageNumber || 1,
    description: filtre?.description || "",
  };
  return axiosInstance.get<DataSinistreResponse>(
    api.report.sinistre.getAllSinistre,
    {
      params: {
        ...filter,
      },
    }
  );
};

export const getOneSinister = (id: string) => {
  return request({
    url: `report/one-sinister?id=${id}`,
    method: "GET",
  });
};

export const getSinistreDetail = (id: string) => {
  return axiosInstance.get<ISinistre>(`${api.report.getDetailReport}/${id}`);
};
