import { useQuery } from "react-query";
import { Loading, Error } from "../Components/LoadingAndError";
import {
  getAllUserPro,
  setProCommission,
  updateCommission,
  updateProCommission,
} from "../Services/user";
import UserProTable from "../Components/Users/UserProTable";
import { Id, ToastContainer } from "react-toastify";
import { Button, Group, Modal, ScrollArea } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { UserProData } from "../types/user";
import { useDisclosure } from "@mantine/hooks";
import { updateCommisionLocationPro } from "../Services/parametrage";
import {
  errorToast,
  loadingToast,
  successToast,
  toastErrorResponseApi,
} from "../Utils/toast";

const CommissionProScreen = () => {
  const [value, setValue] = useState("");
  const modalControl = useDisclosure(false);

  const [data, setdata] = useState<UserProData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const toastId = useRef<Id | null>(null);
  const [loadingSave, setLoadingSave] = useState(false);

  const apiCall = async () => {
    setIsLoading(true);
    try {
      const response = await getAllUserPro();
      setdata(response.data);
    } catch (error: any) {
      errorToast(
        undefined,
        error.response?.data.message || "Une erreur s'est produite..."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const [userEdit, setUserEdit] = useState<UserProData>();

  const handleEditCommission = (item: UserProData) => {
    setUserEdit(item);
    if (item.isConfigured) {
      setValue(item.procommission.commission.toString());
    }
    modalControl[1].open();
  };

  const handleCancelEdit = () => {
    setValue("");
    setUserEdit(undefined);
    modalControl[1].close();
  };

  const handleInputCommisionProChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    try {
      if (value === "") {
        setValue("");
      } else {
        if (/^$|^(-?\d*\.?\d*)$/.test(value)) {
          setValue(value);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveCommission = async () => {
    toastId.current = loadingToast(toastId, "Connexion...");
    setLoadingSave(true);
    try {
      if (userEdit) {
        const response = await updateCommission(
          userEdit.user._id,
          parseInt(value)
        );
        toastId.current = successToast(toastId, "Mise à jour réussie");
        modalControl[1].close();
        setUserEdit(undefined);
        apiCall();
      }
    } catch (error) {
      toastId.current = toastErrorResponseApi(error, toastId);
    } finally {
      setLoadingSave(false);
    }
  };

  useEffect(() => {
    apiCall();
  }, []);

  if (isLoading) return <Loading />;
  if (error) return <Error />;

  return (
    <main className="home">
      <ToastContainer limit={3} />
      <Modal
        opened={modalControl[0]}
        onClose={modalControl[1].close}
        withCloseButton={false}
        centered
        overlayProps={{
          opacity: 0.2,
          blur: 3,
        }}
        scrollAreaComponent={ScrollArea.Autosize}
        size="auto"
        radius="24px"
      >
        <div className="mx-4">
          <h1 className="text-lg font-bold">Modifier commission pro</h1>
          <p>
            Utilisateur : {userEdit?.user.firstname} {userEdit?.user.lastname}
          </p>
          <p>Raison sociale : {userEdit?.user.raisonSociale}</p>
          <div className="mt-2">
            <input
              type="text"
              placeholder="Commission"
              value={value}
              onChange={handleInputCommisionProChange}
              className={`my-1 p-1 px-4 w-full box-border border rounded-[4px] bg-[#00000000] font-[300]
                  placeholder:text-textGrey focus:ring-0 focus:border-[#6b7280]`}
            />
          </div>
          <Group className="my-3">
            <Button
              variant="outline"
              color="red"
              onClick={handleCancelEdit}
              className="w-[150px]"
            >
              Annuler
            </Button>
            <Button
              variant="outline"
              color="green"
              className="w-[150px]"
              disabled={loadingSave || value === ""}
              onClick={handleSaveCommission}
            >
              Enregistrer
            </Button>
          </Group>
        </div>
      </Modal>
      <section className="layout">
        <UserProTable
          data={data || []}
          onClickEditCommission={handleEditCommission}
        />
      </section>
    </main>
  );
};

export default CommissionProScreen;
