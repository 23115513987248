import { Table, ScrollArea, Text, rem, Button, Menu } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { IUser } from "../../types/user";
import { useEffect, useState } from "react";
import { findSubstring } from "../../Utils/fonctionRecherche";

interface TableSelectionProps {
  data: IUser[];
}

export function UserTable({ data }: TableSelectionProps) {
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");

  const [listUser, setlistUser] = useState<IUser[]>(data);

  const rows = listUser.map((item, index) => {
    return (
      <tr key={item._id}>
        <td>{index + 1}</td>
        <td>
          <Text size="sm" weight={500}>
            {item.firstname}
          </Text>
        </td>
        <td>
          <Text size="sm" weight={500}>
            {item.lastname}
          </Text>
        </td>
        <td>{item.email}</td>
        <td>{item.status}</td>
        <td>{item.car.length}</td>
        <td>
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button
                onClick={() => {
                  navigate(`/user/${item._id}`);
                }}
              >
                Voir
              </Button>
            </Menu.Target>
          </Menu>
        </td>
      </tr>
    );
  });

  const filterList = (): IUser[] => {
    return data.filter(
      (item) =>
        findSubstring(item.firstname, searchValue) ||
        findSubstring(item.lastname, searchValue) ||
        findSubstring(item.email, searchValue) ||
        findSubstring(`${item.firstname} ${item.lastname}`, searchValue) ||
        findSubstring(`${item.lastname} ${item.firstname}`, searchValue)
    );
  };
  useEffect(() => {
    setlistUser(filterList());
  }, [searchValue]);

  return (
    <>
      <div className="mb-6">
        <input
          type="text"
          placeholder="Rechercher le nom, prénom ou email"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          className={`p-1 px-4 w-full box-border border rounded-[4px] bg-[#00000000] font-[300]
                  placeholder:text-textGrey focus:ring-0 focus:border-[#6b7280]`}
        />
      </div>
      <ScrollArea>
        <Table miw={800} verticalSpacing="sm">
          <thead>
            <tr>
              <th style={{ width: rem(40) }}>No.</th>
              <th>Nom</th>
              <th>Prénom</th>
              <th>Email</th>
              <th>Status</th>
              <th>Annonces </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
        {listUser.length === 0 && (
          <p className="font-bold text-xl">Aucun résultat</p>
        )}
      </ScrollArea>
    </>
  );
}
