import React from "react";
import Locations from "../Components/Locations";
import { getSinisters } from "../Services/sinisters";
import { useQuery } from "react-query";
import { Error, Loading } from "../Components/LoadingAndError";

function LocationPage() {
  const { data, error, isLoading } = useQuery(["sinisters"], () =>
    getSinisters().then((res) => res.data.reports)
  );

  if (isLoading) return <Loading />;
  if (error) return <Error />;
  return (
    <main className="home">
      <section className="layout">
        <Locations data={data || []} />
      </section>
    </main>
  );
}

export default LocationPage;
