export function findSubstring(chaine: string, searchString: string) {
  const chaineNormalisee = chaine
    .toLowerCase()
    .split("")
    .map(normaliseChar)
    .join("")
    .trim();
  const motRechercheNormalise = searchString
    .toLowerCase()
    .split("")
    .map(normaliseChar)
    .join("")
    .trim();

  return chaineNormalisee.includes(motRechercheNormalise);
}

const normaliseChar = (char: string) => {
  const accents = "ÀÁÂÃÄÅàáâãäåÈÉÊËèéêëÌÍÎÏìíîïÒÓÔÕÖØòóôõöøÙÚÛÜùúûü";
  const sansAccents = "AAAAAAaaaaaaEEEEeeeeIIIIiiiiOOOOOOooooooUUUUuuuu";

  const index = accents.indexOf(char);
  if (index !== -1) {
    return sansAccents[index];
  }

  return char;
};
