import { ScrollArea, Table, rem, Text, Menu, Button } from "@mantine/core";
import { UserProData } from "../../types/user";
import { useEffect, useState } from "react";
import { findSubstring } from "../../Utils/fonctionRecherche";

interface UserProTableProps {
  data: UserProData[];
  onClickEditCommission: (item: UserProData) => void;
}
const UserProTable: React.FC<UserProTableProps> = ({
  data,
  onClickEditCommission,
}) => {
  const [searchValue, setSearchValue] = useState("");

  const [listUser, setlistUser] = useState<UserProData[]>(data);

  const rows = listUser.map((item, index) => {
    return (
      <tr key={item.user._id}>
        <td>{index + 1}</td>
        <td>
          <Text size="sm" weight={500}>
            {item.user.raisonSociale}
          </Text>
        </td>
        <td>
          <Text size="sm" weight={500}>
            {item.user.siren}
          </Text>
        </td>
        <td>{item.user.commission}</td>
        <td>{item.user.email}</td>
        <td>{item.user.status}</td>
        <td>
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button onClick={() => onClickEditCommission(item)}>
                Modifier commission
              </Button>
            </Menu.Target>
          </Menu>
        </td>
      </tr>
    );
  });

  const filterList = (): UserProData[] => {
    return data.filter(
      (item) =>
        findSubstring(item.user.raisonSociale, searchValue) ||
        findSubstring(item.user.siren, searchValue) ||
        findSubstring(item.user.email, searchValue)
    );
  };

  useEffect(() => {
    setlistUser(filterList());
  }, [searchValue]);

  return (
    <>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Rechercher raison sociale, n° siren ou email"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          className={`p-1 px-4 w-full box-border border rounded-[4px] bg-[#00000000] font-[300]
            placeholder:text-textGrey focus:ring-0 focus:border-[#6b7280]`}
        />
      </div>
      <h1 className="font-bold">Liste des utilisateurs Pro</h1>
      <ScrollArea>
        <Table miw={800} verticalSpacing="sm">
          <thead>
            <tr>
              <th style={{ width: rem(40) }}>No.</th>
              <th>Raison sociale</th>
              <th>N° siren</th>
              <th>Commission</th>
              <th>Email</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
        {listUser.length === 0 && (
          <p className="font-bold text-xl">Aucun résultat</p>
        )}
      </ScrollArea>
    </>
  );
};

export default UserProTable;
