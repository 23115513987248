export class StorageUtils {
    public static saveData(key: string, value: string, encrypt?: boolean) {
      window.localStorage.setItem(key, value);
    }
  
    public static getData(key: string): any {
      const value = window.localStorage.getItem(key);
  
      return value;
    }
  
    public static removeData(key: string) {
      window.localStorage.removeItem(key);
    }
  }