import Filter from "../../types/Filter";
import DataUserResponse, { IUser, UserProData } from "../../types/user";
import axiosInstance from "../axios/axiosInstance";
import api from "../endpoint";
import request from "../request";

export const getAllUsers = () => {
  return request({
    url: "office/searchUser?documentCount=1000&pageNumber=1",
    method: "GET",
  });
};

export const ValidateUser = (data: string) => {
  return request({
    url: "office/validateUser?userId=" + data,
    method: "PUT",
    data,
  });
};

//getuserinfo
export const getUserInfo = (id: any) => {
  return request({
    url: "office/getUser?userId=" + id,
    method: "GET",
  });
};

//validate user
export const validateUser = (id: any) => {
  return request({
    url: "office/validateUser?userId=" + id,
    method: "PUT",
  });
};

//refuse user
export const refuseUser = (id: any) => {
  return request({
    url: "office/refuseUser?userId=" + id,
    method: "PUT",
  });
};

//lock user
export const lockUser = (id: string) => {
  return axiosInstance.patch(`${api.user.updateUserState}/${id}/true`);
};

//unlock user
export const unlockUser = (id: any) => {
  return axiosInstance.patch(`${api.user.updateUserState}/${id}/false`);
};

export const getCurrentUser = () => {
  return request({
    url: "user",
    method: "GET",
  });
};

export const getAllUser = (filtre?: Filter) => {
  let filter: Filter = {
    documentCount: filtre?.documentCount || 1000000,
    pageNumber: filtre?.pageNumber || 1,
  };
  return axiosInstance.get<DataUserResponse>(api.user.getAllUser, {
    params: {
      ...filter,
    },
  });
};

export const getUserById = (userId: string) => {
  return axiosInstance.get<IUser>(api.user.getUserById, {
    params: {
      userId,
    },
  });
};

export const getAllUserPro = () => {
  return axiosInstance.get<UserProData[]>(api.user.allUserPro);
};

export const setProCommission = (proUser: string, commission: number) => {
  return axiosInstance.post(api.user.setCommissionPro, {
    commission,
    proUser,
  });
};

export const updateProCommission = (
  commissionId: string,
  commission: number
) => {
  return axiosInstance.patch(
    api.user.updateProCommission(commissionId, commission)
  );
};

export const updateCommission = (userId: string, commission: number) => {
  return axiosInstance.patch(api.user.updateCommission(userId, commission));
};
