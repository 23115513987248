import { Section, Visibility, VisibilityType } from "../../types/help";
import axiosInstance from "../axios/axiosInstance";
import api from "../endpoint";

interface CreateSectionData {
  title: string;
  visibility: string;
}

interface CreateArticleData {
  title: string;
  content: string;
  visibility: string;
  section: string;
}

export const getSectionByUser = (categorieUser: VisibilityType) => {
  return axiosInstance.get<Section[]>(
    `${api.help.getSectionList}/${categorieUser}`
  );
};

export const getVisibilityList = () => {
  return axiosInstance.get<Visibility[]>(api.help.getVisibilityList);
};

export const createSection = (data: CreateSectionData) => {
  return axiosInstance.post(api.help.createSection, data);
};

export const updateSection = (sectionId: string, data: CreateSectionData) => {
  return axiosInstance.patch(`${api.help.updateSection}/${sectionId}`, data);
};

export const deleteSection = (sectionId: string) => {
  return axiosInstance.delete(`${api.help.deleteSection}/${sectionId}`);
};

export const createArticle = (data: CreateArticleData) => {
  return axiosInstance.post(api.help.createArticle, data);
};

export const updateArticle = (articleId: string, data: CreateArticleData) => {
  return axiosInstance.patch(`${api.help.updateArticle}/${articleId}`, data);
};

export const deleteArticle = (articleId: string) => {
  return axiosInstance.delete(`${api.help.deleteArticle}/${articleId}`);
};
