import request from "../request";

export const getStatus = () => {
  return request({
    url: "office/getStatus",
    method: "GET",
  });
};

export const getBugs = () => {
  return request({
    url: "office/getBugs?documentCount=1&pageNumber=1",
    method: "GET",
  });
}