import Filter from "../../types/Filter";
import ApiResponse from "../../types/apiResponse";
import { IReport } from "../../types/report";
import axiosInstance from "../axios/axiosInstance";
import api from "../endpoint";
import request from "../request";

interface DataReportsResponse extends ApiResponse {
  reports: IReport[];
}

export const getBugs = () => {
  return request({
    url: "office/getBugs?documentCount=1&pageNumber=1",
    method: "GET",
  });
};

export const getAllCarReports = () => {
  return request({
    url: "office/getAllCarReport?documentCount=1&pageNumber=1",
    method: "GET",
  });
};

export const getAllProfileReports = () => {
  return request({
    url: `office/getAllProfileReport?documentCount=1&pageNumber=1`,
    method: "GET",
  });
};

// mark as resolved
// Path: src/Services/bugs/index.ts
export const markAsResolved = (id: string) => {
  return request({
    url: `office/markReportAsSeen?reportId=${id}`,
    method: "PUT",
  });
};

export const getAllBugs = (filtre?: Filter) => {
  let filter: Filter = {
    documentCount: filtre?.documentCount || 1000000,
    pageNumber: filtre?.pageNumber || 1,
    description: filtre?.description || "",
  };
  return axiosInstance.get<DataReportsResponse>(api.report.bugs, {
    params: {
      ...filter,
    },
  });
};

export const getCarReports = (filtre?: Filter) => {
  let filter: Filter = {
    documentCount: filtre?.documentCount || 1000000,
    pageNumber: filtre?.pageNumber || 1,
    description: filtre?.description || "",
  };
  return axiosInstance.get<DataReportsResponse>(api.report.car, {
    params: {
      ...filter,
    },
  });
};

export const getProfileReports = (filtre?: Filter) => {
  let filter: Filter = {
    documentCount: filtre?.documentCount || 1000000,
    pageNumber: filtre?.pageNumber || 1,
    description: filtre?.description || "",
  };
  return axiosInstance.get<DataReportsResponse>(api.report.profile, {
    params: {
      ...filter,
    },
  });
};

export const getDetailReport = (id: string) => {
  return axiosInstance.get(`${api.report.getDetailReport}/${id}`);
};

export const makeReportAsSeen = (reportId: string) => {
  return axiosInstance.put(api.report.markAsSeen, undefined, {
    params: {
      reportId
    },
  });
};
