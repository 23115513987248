import React, { useEffect, useState } from "react";
import "./oneUser.scss";
import { useParams } from "react-router-dom";
import { getOneSinister, getSinistreDetail } from "../Services/sinisters";
import { Image } from "@mantine/core";
import { ISinistre } from "../types/sinistre";
import { withBaseUrlImage } from "../Utils/utils";
import { Carousel } from "@mantine/carousel";
import { Error, Loading } from "../Components/LoadingAndError";

function OneSinister() {
  const [status, setStatus] = useState<ISinistre>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, seterror] = useState(false);

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getSinistreDetail(id)
        .then((res) => {
          setStatus(res.data);
        })
        .catch((error) => {
          seterror(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  const Container = (props: any) => {
    return (
      <div key={props.lable} className="container">
        <div className="left">
          <p style={{ textAlign: "center" }}>{props.lable}:</p>
        </div>
        <div className="right">{props.value}</div>
      </div>
    );
  };

  if (isLoading) return <Loading />;
  if (error) return <Error />;

  return (
    <main className="home">
      <section className="layout">
        <section className="customTable">
          {status && status.images.length > 0 && (
            <Container
              lable="Photo"
              value={
                <>
                  <Image
                    height={400}
                    width={400}
                    fit="contain"
                    src={withBaseUrlImage(status?.images[0] || "")}
                    alt="voiture"
                  />
                  {/* <Carousel
                    withIndicators
                    loop
                    classNames={{
                      root: "aspect-[11/9] w-full",
                      viewport: " w-full h-full",
                      container: " w-full h-full",
                      slide: " w-full",
                      indicator: "h-[3px] bg-white",
                    }}
                  >
                    {status?.images.map((img) => {
                      return (
                        <Carousel.Slide className="">
                          <Image
                            height={400}
                            width={400}
                            fit="contain"
                            src={withBaseUrlImage(img)}
                            alt="voiture"
                          />
                        </Carousel.Slide>
                      );
                    })}
                  </Carousel> */}
                </>
              }
            />
          )}
          <Container lable="Type de sinistre" value={status?.sinisterType} />
          <Container lable="Description" value={status?.description} />
        </section>
      </section>
    </main>
  );
}

export default OneSinister;
