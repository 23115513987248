import { useEffect, useRef, useState } from "react";
import { Id, ToastContainer, toast } from "react-toastify";
import {
  loadingToast,
  successToast,
  toastErrorResponseApi,
} from "../../Utils/toast";
import { Article, Section, Visibility } from "../../types/help";
import {
  Button,
  Group,
  LoadingOverlay,
  Modal,
  ScrollArea,
  Select,
  SelectItem,
} from "@mantine/core";
import { createArticle, updateArticle } from "../../Services/help/help";

interface AddQuestionProps {
  listVisibility: Visibility[];
  listSectionOwner: Section[];
  listSectionCustomer: Section[];
  listSectionAll: Section[];
  close: () => void;
  opened: boolean;
  listSectionApiCall: Function;
  articleToEdit: Article | null;
  setArticleToEdit: React.Dispatch<React.SetStateAction<Article | null>>;
}

const AddQuestion: React.FC<AddQuestionProps> = ({
  listSectionApiCall,
  listSectionOwner,
  listSectionCustomer,
  listSectionAll,
  listVisibility,
  close,
  opened,
  articleToEdit,
  setArticleToEdit,
}) => {
  // --------------------------------------------------------------------
  const toastId = useRef<null | Id>(null);
  const [value, setValue] = useState("");
  const [content, setContent] = useState("");
  const [section, setSection] = useState<string | null>(null);
  const [valueVisibilitySelect, setValueVisibilitySelect] = useState<
    string | null
  >("all");

  const [isLoading, setIsLoading] = useState(false);

  const [dataSelectSection, setDataSelectSection] = useState<SelectItem[]>([]);
  // --------------------------------------------------------------------

  const isValidAddQuestion = () => {
    if (value === "") return false;
    if (valueVisibilitySelect === null) return false;
    if (section === null) return false;
    if (content === "") return false;
    return true;
  };

  const getSelectData = (list: Section[]): SelectItem[] => {
    let listSelect: SelectItem[] = [];
    list.forEach((item) => {
      listSelect.push({
        label: item.title,
        value: item.id,
      });
    });
    return listSelect;
  };

  useEffect(() => {
    switch (valueVisibilitySelect) {
      case "all":
        setDataSelectSection(getSelectData(listSectionAll));
        setSection(null);
        break;
      case "customer":
        setDataSelectSection(getSelectData(listSectionCustomer));
        setSection(null);
        break;
      case "owner":
        setDataSelectSection(getSelectData(listSectionOwner));
        setSection(null);
        break;
      default:
        setDataSelectSection([]);
        setSection(null);
    }
  }, [valueVisibilitySelect]);

  useEffect(() => {
    if (articleToEdit) {
      setValue(articleToEdit.title);
      setContent(articleToEdit.content);
      setValueVisibilitySelect(articleToEdit.visibility);
      setSection(articleToEdit.section);
    }
  }, [articleToEdit]);

  useEffect(() => {
    console.log(section);
  }, [section]);

  const handleCreate = async () => {
    if (!isValidAddQuestion()) {
      return toast("Veuillez completer tous les champs");
    }
    setIsLoading(true);
    const data = {
      title: value,
      content,
      visibility: valueVisibilitySelect || "",
      section: section || "",
    };
    toastId.current = loadingToast(toastId);
    try {
      if (articleToEdit === null) {
        const response = await createArticle(data);
        toastId.current = successToast(toastId, "Article créé avec succès !");
      } else {
        const response = await updateArticle(articleToEdit._id, data);
        setArticleToEdit(null);
        toastId.current = successToast(
          toastId,
          "L'article a été mis à jour avec succès."
        );
      }
      setValue("");
      setContent("");
      setSection(null);
      setValueVisibilitySelect("all");
      close();
      listSectionApiCall();
    } catch (error) {
      toastId.current = toastErrorResponseApi(error, toastId);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setValue("");
    setContent("");
    setSection(null);
    setValueVisibilitySelect("all");
    setArticleToEdit(null);
    close();
  };

  return (
    <>
      <LoadingOverlay className="fixed" visible={isLoading} />
      <ToastContainer limit={3} />
      <Modal
        opened={opened}
        onClose={close}
        withCloseButton={false}
        centered
        overlayProps={{
          opacity: 0.2,
          blur: 3,
        }}
        scrollAreaComponent={ScrollArea.Autosize}
        size="lg"
        radius="24px"
      >
        <div className="p-4">
          <div className="flex flex-col w-full my-1">
            <p>Entrer la question</p>
            <input
              type="text"
              placeholder="Titre"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className={`my-1 p-1 px-4 w-full box-border border rounded-[4px] bg-[#00000000] font-[300]
                  placeholder:text-textGrey focus:ring-0 focus:border-[#6b7280]`}
            />
          </div>
          <div className="flex flex-col w-full my-1">
            <p>Entrer le contenu</p>
            <textarea
              placeholder="Réponse de la question"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              className={`my-1 p-1 py-2 px-4 w-full box-border border rounded-[4px] bg-[#00000000] font-[300]
                  placeholder:text-textGrey focus:ring-0 focus:border-[#6b7280]`}
            />
          </div>
          <div className="flex flex-col w-full my-1"></div>
          <div className="flex flex-col w-full my-1">
            <p>Visible pour :</p>
            <Select
              data={listVisibility}
              value={valueVisibilitySelect}
              onChange={(value) => setValueVisibilitySelect(value)}
              classNames={{
                input:
                  " bg-[#00000000] font-origin placeholder:text-grey2 focus:ring-0 focus:border-[#6b7280]",
              }}
              className="rounded-[4px] bg-[#0000] box-border w-full border-none focus:ring-0 p-0 my-1
                    placeholder:text-grey2"
            />
          </div>
          <div className="flex flex-col w-full my-1">
            <p>Question pour la section </p>
            <Select
              data={dataSelectSection}
              value={section}
              onChange={(value) => setSection(value)}
              classNames={{
                input:
                  " bg-[#00000000] font-origin placeholder:text-grey2 focus:ring-0 focus:border-[#6b7280]",
              }}
              className="rounded-[4px] bg-[#0000] box-border w-full border-none focus:ring-0 p-0 my-1
                    placeholder:text-grey2"
            />
          </div>
          <Group className="my-3">
            <Button
              variant="outline"
              color="red"
              onClick={handleCancel}
              className="w-[150px]"
            >
              Annuler
            </Button>
            <Button
              variant="outline"
              color="green"
              className="w-[150px]"
              disabled={!isValidAddQuestion() || isLoading}
              onClick={handleCreate}
            >
              {articleToEdit === null ? "Créer" : "Modifier"}
            </Button>
          </Group>
        </div>
      </Modal>
    </>
  );
};

export default AddQuestion;
