import {
  Table,
  ScrollArea,
  rem,
  Button,
  Menu,
  Select,
  Pagination,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { ICar } from "../../types/car";
import { useEffect, useState } from "react";
import { findSubstring } from "../../Utils/fonctionRecherche";

interface TableSelectionProps {
  data: ICar[];
}

export function CarsTable({ data }: TableSelectionProps) {
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");
  const [sort, setSort] = useState<string | null>("recent");
  const [departementFilter, setDepartementFilter] = useState<string | null>(
    "tous"
  );

  const [listCar, setlistCar] = useState<ICar[]>(data);

  const rows = listCar.map((item, index) => {
    return (
      <tr key={item._id}>
        <td>{index + 1}</td>
        <td>{item.brand}</td>
        <td>{item.model}</td>
        <td>{item.type}</td>
        <td>{item.licensePlate}</td>
        <td>{item.city}</td>
        <td>{item.status}</td>
        <td>
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button
                onClick={() => {
                  navigate(`/car/${item._id}`);
                }}
              >
                Voir
              </Button>
            </Menu.Target>
          </Menu>
        </td>
      </tr>
    );
  });

  const filterList = (): ICar[] => {
    let list: ICar[] = [];
    list = data.filter(
      (item) =>
        findSubstring(item.brand, searchValue) ||
        findSubstring(item.model, searchValue) ||
        findSubstring(item.licensePlate, searchValue) ||
        findSubstring(`${item.brand} ${item.model}`, searchValue) ||
        findSubstring(item.city, searchValue)
    );
    list = list.filter((item) => {
      if (departementFilter === "tous") {
        return true;
      } else return item.island === departementFilter;
    });
    if (sort === "ancien") list = list.reverse();
    return list;
  };

  useEffect(() => {
    setlistCar(filterList());
  }, [searchValue, sort, departementFilter]);

  return (
    <>
      <div className="mb-4">
        <div>
          <input
            type="text"
            placeholder="Rechercher marque, modèle, plaque d'immatriculation, ville"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            className={`p-1 px-4 w-full box-border border rounded-[4px] bg-[#00000000] font-[300]
            placeholder:text-textGrey focus:ring-0 focus:border-[#6b7280]`}
          />
        </div>
        <div className="w-full">
          <p className="flex flex-row my-3 items-center w-full">
            <span className="mr-2 min-w-[150px]">Département </span>
            <Select
              radius={"md"}
              value={departementFilter}
              onChange={(value) => setDepartementFilter(value)}
              data={[
                { value: "tous", label: "Tous" },
                { value: "guadeloupe", label: "Guadeloupe" },
                { value: "martinique", label: "Martinique" },
                { value: "guyane", label: "Guyane" },
                { value: "réunion", label: "Réunion" },
              ]}
              classNames={{
                input: "text-md text-textBlack border-[1px] border-textBlack",
              }}
            />
          </p>
          <p className="flex flex-row my-3 items-center w-full">
            <span className="mr-2 min-w-[150px]">Trier par</span>
            <Select
              radius={"md"}
              data={[
                { value: "recent", label: "Plus récent" },
                { value: "ancien", label: "Plus ancien" },
              ]}
              value={sort}
              onChange={(value) => setSort(value)}
              classNames={{
                input: "text-md text-textBlack",
              }}
            />
          </p>
        </div>
        {/* <div className="w-full flex flex-row justify-end">
          <p>Li</p>
          <Pagination total={20} siblings={1} defaultValue={10} />
        </div> */}
      </div>
      <h1>Résultat : {listCar.length} voiture(s) </h1>
      <ScrollArea>
        <Table miw={800} verticalSpacing="sm">
          <thead>
            <tr>
              <th style={{ width: rem(40) }}>No.</th>
              <th>Marque</th>
              <th>Modèle</th>
              <th>Type</th>
              <th>Plaque d'immatriculation</th>
              <th>Ville</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
        {listCar.length === 0 && (
          <p className="font-bold text-xl">Aucun résultat</p>
        )}
      </ScrollArea>
    </>
  );
}
