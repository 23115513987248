import {
  Button,
  Group,
  LoadingOverlay,
  Modal,
  ModalProps,
  ScrollArea,
  Select,
} from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import { Section, Visibility } from "../../types/help";
import { Id, ToastContainer, toast } from "react-toastify";
import {
  loadingToast,
  successToast,
  toastErrorResponseApi,
} from "../../Utils/toast";
import { createSection, updateSection } from "../../Services/help/help";

interface AddSectionProps {
  listVisibility: Visibility[];
  opened: boolean;
  close: () => void;
  listSectionApiCall: Function;
  sectionEdit: Section | null;
  setSectionToEdit: React.Dispatch<React.SetStateAction<Section | null>>;
}

const AddSection: React.FC<AddSectionProps> = ({
  listVisibility,
  listSectionApiCall,
  sectionEdit,
  setSectionToEdit,
  opened,
  close,
}) => {
  // --------------------------------------------------------------------

  console.log(sectionEdit);

  const [value, setValue] = useState("");
  const [valueVisibilitySelect, setValueVisibilitySelect] = useState<
    string | null
  >("all");

  const [isLoading, setIsLoading] = useState(false);
  const toastId = useRef<Id | null>(null);
  // --------------------------------------------------------------------

  const isValidAddSection = () => {
    if (value === "") return false;
    if (valueVisibilitySelect === null) return false;
    return true;
  };

  const handleCreateSection = async () => {
    if (!isValidAddSection()) {
      return toast("Veuillez completer tous les champs");
    }
    setIsLoading(true);
    const data = {
      title: value,
      visibility: valueVisibilitySelect || "",
    };
    toastId.current = loadingToast(toastId);
    try {
      if (sectionEdit === null) {
        const response = await createSection(data);
      } else {
        const response = await updateSection(sectionEdit.id, data);
        setSectionToEdit(null);
      }
      toastId.current = successToast(
        toastId,
        sectionEdit === null
          ? "Catégorie créée avec succès"
          : "La catégorie a été modifiée avec succès."
      );
      setValue("");
      setValueVisibilitySelect("all");
      listSectionApiCall();
      close();
    } catch (error) {
      toastId.current = toastErrorResponseApi(error, toastId);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setValue("");
    setValueVisibilitySelect("all");
    setSectionToEdit(null);
    close();
  };

  useEffect(() => {
    if (sectionEdit) {
      setValue(sectionEdit.title);
      setValueVisibilitySelect(sectionEdit.visibility);
    }
  }, [sectionEdit]);
  return (
    <>
      <ToastContainer limit={3} />
      <Modal
        opened={opened}
        onClose={close}
        withCloseButton={false}
        centered
        overlayProps={{
          opacity: 0.2,
          blur: 3,
        }}
        scrollAreaComponent={ScrollArea.Autosize}
        size="lg"
        radius="24px"
      >
        <div className="p-4">
          <div className="flex flex-col w-full my-1">
            <p>Entrer le titre de la catégorie</p>
            <input
              type="text"
              placeholder="Titre"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className={`my-1 p-1 px-4 w-full box-border border rounded-[4px] bg-[#00000000] font-[300]
                  placeholder:text-textGrey focus:ring-0 focus:border-[#6b7280]`}
            />
          </div>
          <div className="flex flex-col w-full my-1">
            <p>Visible pour :</p>
            <Select
              data={listVisibility}
              value={valueVisibilitySelect}
              onChange={(value) => setValueVisibilitySelect(value)}
              classNames={{
                input:
                  " bg-[#00000000] font-origin placeholder:text-grey2 focus:ring-0 focus:border-[#6b7280]",
              }}
              className="rounded-[4px] bg-[#0000] box-border w-full border-none focus:ring-0 p-0 my-1
                    placeholder:text-grey2"
            />
          </div>
          <Group className="my-3">
            <Button
              variant="outline"
              color="red"
              onClick={handleCancel}
              className="w-[150px]"
            >
              Annuler
            </Button>
            <Button
              variant="outline"
              color="green"
              className="w-[150px]"
              disabled={!isValidAddSection() || isLoading}
              onClick={handleCreateSection}
            >
              {sectionEdit === null ? "Créer" : "Modifier"}
            </Button>
          </Group>
        </div>
      </Modal>
    </>
  );
};

export default AddSection;
