import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Group,
  LoadingOverlay,
  Modal,
  ScrollArea,
  createStyles,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { successToast, toastErrorResponseApi } from "../../Utils/toast";
import {
  deleteArticle,
  deleteSection,
  getSectionByUser,
  getVisibilityList,
} from "../../Services/help/help";
import { Article, Section, Visibility } from "../../types/help";
import SectionItem from "./SectionItem";
import AddSection from "./addSection";
import AddQuestion from "./addQuestion";

export const useStyles = createStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontWeight: 500,
    fontSize: "40px",
    lineHeight: "5px",
    textTransform: "uppercase",
    textDecoration: "underline",
  },
  list: {
    fontSize: "24px",
    lineHeight: "5px",
    fontWeight: 500,
    display: "inline-block",
  },
  add: {
    fontSize: "24px",
    lineHeight: "5px",
    fontWeight: 500,
    color: "rgba(255,255,255,0.5)",
    marginRight: "15px",
  },
  img: {
    width: "20px",
    height: "20px",
    alignSelf: "center",
    marginTop: "4px",
  },
  containerParagraph: {
    display: "flex",
  },
}));

export const getRandomNumber = () => {
  const random = Math.random();
  const randomNumber = Math.floor(random * 51);
  return randomNumber;
};

const Helps = () => {
  // ----------------------------------------------------------------------
  const [listSectionOwner, setlistSectionOwner] = useState<Section[]>([]);
  const [listSectionCustomer, setlistSectionCustomer] = useState<Section[]>([]);
  const [listSectionAll, setlistSectionAll] = useState<Section[]>([]);
  const [isLoadingListSectionOwner, setIsLoadingListSectionOwner] =
    useState(false);

  const [listVisibility, setListVisibility] = useState<Visibility[]>([]);
  const [isLoadingListVisibility, setIsLoadingListVisibility] = useState(false);

  const [sectionToEdit, setSectionToEdit] = useState<Section | null>(null);
  const [articleToEdit, setArticleToEdit] = useState<Article | null>(null);

  const [sectionToDelete, setSectionToDelete] = useState<Section | null>(null);
  const [articleToDelete, setArticleToDelete] = useState<Article | null>(null);

  const [opened, { close, open }] = useDisclosure(false);
  const addQuestionModalControl = useDisclosure(false);

  const confirmationModalControl = useDisclosure(false);
  const confirmModalDeleteArticleControl = useDisclosure(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  // ----------------------------------------------------------------------

  const listVisibilityApiCall = async () => {
    setIsLoadingListVisibility(true);
    try {
      const response = await getVisibilityList();
      setListVisibility(response.data);
    } catch (error) {
    } finally {
      setIsLoadingListVisibility(false);
    }
  };

  const listSectionApiCall = async () => {
    setIsLoadingListSectionOwner(true);
    try {
      const responseOwner = await getSectionByUser("owner");
      setlistSectionOwner(responseOwner.data);
      const responseCustomer = await getSectionByUser("customer");
      setlistSectionCustomer(responseCustomer.data);
      const responseAll = await getSectionByUser("all");
      setlistSectionAll(responseAll.data);
    } catch (error) {
    } finally {
      setIsLoadingListSectionOwner(false);
    }
  };

  useEffect(() => {
    listSectionApiCall();
    listVisibilityApiCall();
  }, []);

  const handleEditSection = (section: Section) => {
    setSectionToEdit(section);
    open();
  };

  const handleOnDelete = (section: Section) => {
    setSectionToDelete(section);
    confirmationModalControl[1].open();
  };

  const handleEditArticle = (article: Article) => {
    setArticleToEdit(article);
    addQuestionModalControl[1].open();
  };

  const handleOnDeleteArticle = (article: Article) => {
    setArticleToDelete(article);
    confirmModalDeleteArticleControl[1].open();
  };

  const handleConfirmDeleteSection = async () => {
    if (sectionToDelete) {
      setIsLoadingDelete(true);
      try {
        const response = await deleteSection(sectionToDelete.id);
        confirmationModalControl[1].close();
        successToast(undefined, "La catégorie a été supprimée avec succès");
        listSectionApiCall();
      } catch (error) {
        toastErrorResponseApi(error);
      } finally {
        setIsLoadingDelete(false);
      }
    }
  };

  const handleConfirmDeleteArticle = async () => {
    if (articleToDelete) {
      setIsLoadingDelete(true);
      try {
        const response = await deleteArticle(articleToDelete._id);
        confirmModalDeleteArticleControl[1].close();
        successToast(undefined, "L'article a été supprimé avec succès.");
        listSectionApiCall();
      } catch (error) {
        toastErrorResponseApi(error);
      } finally {
        setIsLoadingDelete(false);
      }
    }
  };

  // if (isLoadingListSectionOwner) return <Loading />;
  return (
    <div>
      <LoadingOverlay
        className="fixed"
        visible={
          isLoadingListSectionOwner ||
          isLoadingListSectionOwner ||
          isLoadingListVisibility ||
          isLoadingDelete
        }
      />
      <Modal
        opened={confirmationModalControl[0]}
        onClose={confirmationModalControl[1].close}
        withCloseButton={false}
        centered
        overlayProps={{
          opacity: 0.2,
          blur: 3,
        }}
        scrollAreaComponent={ScrollArea.Autosize}
        size="auto"
        radius="24px"
      >
        <div className="flex flex-col items-center text-xs px-4">
          <h1 className="font-[600]">Suppression</h1>
          <div className="h-[1px] bg-grey1 w-full mb-4 mt-3 mx-2" />
          <p className="mt-2 text-center w-[80%] text-sm">
            Voulez vous supprimer cette catégorie?
          </p>

          <div className="flex felx-row w-full items-center justify-center mt-4">
            <div className="mr-2">
              <Button
                variant="outline"
                color="gray"
                className="w-[150px]"
                onClick={() => confirmationModalControl[1].close()}
              >
                Annuler
              </Button>
            </div>
            <div className="ml-2">
              <Button
                variant="outline"
                color="red"
                className="w-[150px]"
                onClick={handleConfirmDeleteSection}
              >
                Supprimer
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        opened={confirmModalDeleteArticleControl[0]}
        onClose={confirmModalDeleteArticleControl[1].close}
        withCloseButton={false}
        centered
        overlayProps={{
          opacity: 0.2,
          blur: 3,
        }}
        scrollAreaComponent={ScrollArea.Autosize}
        size="auto"
        radius="24px"
      >
        <div className="flex flex-col items-center text-xs px-4">
          <h1 className="font-[600]">Suppression</h1>
          <div className="h-[1px] bg-grey1 w-full mb-4 mt-3 mx-2" />
          <p className="mt-2 text-center w-[80%] text-sm">
            Voulez vous supprimer cette question?
          </p>

          <div className="flex felx-row w-full items-center justify-center mt-4">
            <div className="mr-2">
              <Button
                variant="outline"
                color="gray"
                className="w-[150px]"
                onClick={() => confirmModalDeleteArticleControl[1].close()}
              >
                Annuler
              </Button>
            </div>
            <div className="ml-2">
              <Button
                variant="outline"
                color="red"
                className="w-[150px]"
                onClick={handleConfirmDeleteArticle}
              >
                Supprimer
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <div>
        <Group>
          <Button variant="outline" color="green" onClick={() => open()}>
            Créer une catégorie
          </Button>
          <Button
            variant="outline"
            color="green"
            onClick={() => addQuestionModalControl[1].open()}
          >
            Ajouter une question
          </Button>
        </Group>

        <AddSection
          opened={opened}
          close={close}
          listVisibility={listVisibility}
          listSectionApiCall={listSectionApiCall}
          sectionEdit={sectionToEdit}
          setSectionToEdit={setSectionToEdit}
        />

        <AddQuestion
          listSectionAll={listSectionAll}
          listSectionOwner={listSectionOwner}
          listSectionCustomer={listSectionCustomer}
          listVisibility={listVisibility}
          listSectionApiCall={listSectionApiCall}
          close={addQuestionModalControl[1].close}
          opened={addQuestionModalControl[0]}
          articleToEdit={articleToEdit}
          setArticleToEdit={setArticleToEdit}
        />
      </div>
      <div>
        <div>
          <h1 className="font-bold">Pour les propriétaires</h1>
          <div className="my-3">
            <Accordion>
              {listSectionOwner.map((section) => {
                return (
                  <SectionItem
                    key={section.id}
                    section={section}
                    onEdit={() => handleEditSection(section)}
                    onDelete={() => handleOnDelete(section)}
                    onEditArticle={handleEditArticle}
                    onDeleteArticle={handleOnDeleteArticle}
                  />
                );
              })}
            </Accordion>
          </div>
        </div>
        <div>
          <h1 className="font-bold">Pour les locataires</h1>
          <div className="my-3">
            <Accordion>
              {listSectionCustomer.map((section) => {
                return (
                  <SectionItem
                    key={section.id}
                    section={section}
                    onEdit={() => handleEditSection(section)}
                    onDelete={() => handleOnDelete(section)}
                    onEditArticle={handleEditArticle}
                    onDeleteArticle={handleOnDeleteArticle}
                  />
                );
              })}
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Helps;
