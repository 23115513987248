import { ReservationsTable } from "../Components/Reservations/index";
import { getListReservation, getReservations } from "../Services/reservations";
import { useQuery } from "react-query";
import { Loading, Error } from "../Components/LoadingAndError/index";

function ReservationPage() {
  const { data, error, isLoading } = useQuery(["reservations"], () =>
    getListReservation().then((res) => res.data.rents)
  );
  if (isLoading) return <Loading />;
  if (error) return <Error />;

  return (
    <main className="home">
      <section className="layout">
        <ReservationsTable data={data?.length && data.length > 0 ? data : []} />
      </section>
    </main>
  );
}

export default ReservationPage;
