import { Id, toast } from "react-toastify";

export const errorToast = (
  toastId?: React.MutableRefObject<Id | null>,
  message?: string
): null | Id => {
  toast.onChange(payload => {
    if (toastId && payload.status === "removed") {
      toastId.current = null;
    }
  });
  if (toastId && toastId.current !== null) {
    toast.update(toastId.current, {
      render: message || "Une erreur s'est produite.",
      isLoading: false,
      autoClose: 2000
    });
    return null;
  } else {
    return toast.error(message || "Une erreur s'est produite.");
  }
};

export const successToast = (
  toastId?: React.MutableRefObject<Id | null>,
  message?: string
): null | Id => {
  toast.onChange(payload => {
    if (toastId && payload.status === "removed") {
      toastId.current = null;
    }
  });
  if (toastId && toastId.current !== null) {
    toast.update(toastId.current, {
      render: message || "Donnée envoyé avec succes",
      isLoading: false,
      autoClose: 2000
    });
    return null;
  } else {
    return toast.success(message || "Donnée envoyé avec succes");
  }
};

export const loadingToast = (
  toastId: React.MutableRefObject<Id | null>,
  message?: string
): null | Id => {
  // toast.onChange(payload => {
  //   if (payload.status === "removed") {
  //     toastId.current = null;
  //   }
  // });
  if (toastId && toastId.current !== null) {
    toast.update(toastId.current, {
      render: message || "Connexion...",
      isLoading: true,
    });
    return null;
  } else {
    return toast.loading(message || "Connexion...", {
      position: "top-right"
    });
  }
};

export const toastErrorResponseApi = (
  error: any,
  toastId?: React.MutableRefObject<Id | null>,
  attribut?: string
): null | Id => {
  let message = "Une erreur est survenue.";
  let attr = attribut ? attribut : "message";
  if (error.response?.data?.[attr]) {
    message = error.response?.data?.[attr];
    if (message === "Internal server error") {
      message = "Erreur de connexion.";
    }
  } else if ((error.code = "ECONNABORTED")) {
    message = "Erreur de connexion.";
  }
  return errorToast(toastId, message);
};
