import { Accordion, Button, Group } from "@mantine/core";
import { Article, Section } from "../../types/help";

interface SectionItemProps {
  section: Section;
  onEdit: () => void;
  onDelete: () => void;
  onEditArticle: Function;
  onDeleteArticle: Function;
}

const SectionItem: React.FC<SectionItemProps> = ({
  section,
  onEdit,
  onDelete,
  onDeleteArticle,
  onEditArticle,
}) => {
  return (
    <Accordion.Item key={section.id} value={section.id}>
      <Accordion.Control>
        <p>{section.title}</p>
      </Accordion.Control>
      <Accordion.Panel>
        <div className="ml-4">
          <Group>
            <Button
              variant="outline"
              color="orange"
              className="text-xs"
              onClick={onEdit}
            >
              Modifier
            </Button>
            <Button
              variant="outline"
              color="red"
              className="text-xs"
              onClick={onDelete}
            >
              Supprimer
            </Button>
          </Group>
          <div className="mt-4">
            <Accordion>
              {section.articles.map((article) => {
                return (
                  <div>
                    <Accordion.Item key={article._id} value={article._id}>
                      <Accordion.Control>{article.title}</Accordion.Control>
                      <Accordion.Panel>
                        <p>{article.content}</p>
                        <div className="mt-2">
                          <Group>
                            <Button
                              variant="outline"
                              color="orange"
                              className="text-xs"
                              onClick={() => onEditArticle(article)}
                            >
                              Modifier
                            </Button>
                            <Button
                              variant="outline"
                              color="red"
                              className="text-xs"
                              onClick={() => onDeleteArticle(article)}
                            >
                              Supprimer
                            </Button>
                          </Group>
                        </div>
                      </Accordion.Panel>
                    </Accordion.Item>
                  </div>
                );
              })}
            </Accordion>
          </div>
        </div>
      </Accordion.Panel>
    </Accordion.Item>
  );
};

export default SectionItem;
