import { Link, useNavigate } from "react-router-dom";
import { IReport } from "../../types/report";
import { Button, ScrollArea, Table, rem } from "@mantine/core";
import moment from "moment";

export interface ReportTableProps {
  data: IReport[];
  onMarkAsSeen: Function;
}

const ProfileReportTable: React.FC<ReportTableProps> = ({
  data,
  onMarkAsSeen,
}) => {
  const rows = data.map((item, index) => {
    return (
      <tr key={item._id}>
        <td>{index + 1}</td>
        <td>{item.description}</td>
        <td>
          <Link to={`/user/${item.profile}`} className="link">
            {item.profile}
          </Link>
        </td>
        <td>{moment(new Date(item.date)).format("DD/MM/YYYY")}</td>
        <td>{item.isSeen ? "Oui" : "Non"}</td>
        <td>
          <Button onClick={() => onMarkAsSeen(item._id)}>Vu</Button>
        </td>
      </tr>
    );
  });

  return (
    <ScrollArea>
      <Table miw={800} verticalSpacing="sm">
        <thead>
          <tr>
            <th style={{ width: rem(40) }}>No.</th>
            <th>Description</th>
            <th>Profile</th>
            <th>Date</th>
            <th>Vu</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </ScrollArea>
  );
};

export default ProfileReportTable;
