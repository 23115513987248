import { Button, Menu, ScrollArea, Table, rem } from "@mantine/core";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { ISinistre } from "../../types/sinistre";
import moment from "moment";

interface Props {
  data: ISinistre[];
}
function Locations({ data }: Props) {
  const navigate = useNavigate();
  const rows = data?.map((item, index) => {
    return (
      <tr key={item._id}>
        <td>{index + 1}</td>
        <td>{item.sinisterType}</td>
        <td>{item.description}</td>
        <td>
          <Link
            to={`/user/${item.user}`}
            style={{ textDecoration: "none", color: "#C1C2C5" }}
          >
            {item.user}
          </Link>
        </td>
        <td>
          <Link
            to={`/car/${item.car}`}
            style={{ textDecoration: "none", color: "#C1C2C5" }}
          >
            {item.car}
          </Link>
        </td>
        <td>{item.isSeen ? "Oui" : "Non"}</td>
        <td>{moment(new Date(item.date)).format("DD/MM/YYYY à HH:MM")}</td>
        <td>
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button
                onClick={() => {
                  navigate(`/sinister/${item._id}`);
                }}
              >
                Voir
              </Button>
            </Menu.Target>
          </Menu>
        </td>
      </tr>
    );
  });
  return (
    <ScrollArea>
      <Table>
        <thead>
          <tr>
            <th style={{ width: rem(40) }}>No.</th>
            <th>Type de sinistre</th>
            <th>Description</th>
            <th>Utilisateur</th>
            <th>Car</th>
            <th>Vu</th>
            <th>Date</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </ScrollArea>
  );
}

export default Locations;
