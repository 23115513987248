import { StorageUtils } from "./StorageUtils";

export class CurrentUser {
  public static isLoged() {
    return !!StorageUtils.getData("access-token");
  }
  public static logout() {
    StorageUtils.removeData("access-token");
    StorageUtils.removeData("refresh-token");
    StorageUtils.removeData("profil");
    window.location.replace("/");
  }
}
