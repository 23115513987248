import { useEffect, useState } from "react";
import {
  getOneReservation,
  getReservationDetail,
} from "../Services/reservations";
import { Link, useParams } from "react-router-dom";
import { Button, Group } from "@mantine/core";
import "./oneUser.scss";
import { IRentDetail } from "../types/rent";
import moment from "moment";
import { Error, Loading } from "../Components/LoadingAndError";

function OneReservation() {
  const [status, setStatus] = useState<IRentDetail>();
  const { id } = useParams<{ id: string }>();

  const [isLoading, setIsLoading] = useState(true);
  const [error, seterror] = useState(false);

  useEffect(() => {
    if (id) {
      getReservationDetail(id)
        .then((res) => {
          setStatus(res.data);
          console.log(res.data);
        })
        .catch((error) => {
          seterror(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id]);

  const Container = (props: any) => {
    return (
      <div key={props.lable} className="container">
        <div className="left">{props.lable}</div>
        <div className="right">{props.value}</div>
      </div>
    );
  };

  if (isLoading) return <Loading />;
  if (error) return <Error />;

  return (
    <main className="home">
      <section className="layout">
        <Group>
          <Button variant="outline" onClick={() => window.history.back()}>
            {"< Retour "}
          </Button>
        </Group>

        <section className="customTable">
          <Container lable="ID" value={status?._id} />
          <Container
            lable="Reservé Par"
            value={
              <Link to={"/user/" + status?.customer._id}>
                {status?.customer.firstname} {status?.customer.lastname}
              </Link>
            }
          />
          <Container
            lable="Voiture "
            value={
              <Link to={"/car/" + status?.car._id}>
                {status?.car.brand} {status?.car.model}
              </Link>
            }
          />
          <Container
            lable="Propataire de voiture"
            value={
              <Link to={"/user/" + status?.carOwner._id}>
                {" "}
                {status?.carOwner.firstname} {status?.carOwner.lastname}
              </Link>
            }
          />
          <Container lable="status?" value={status?.status} />
          <Container lable="À payer" value={`${status?.toPay}€`} />
          <Container lable="Jours" value={status?.dayCount} />
          <Container
            lable="Début"
            value={
              status?.date &&
              moment(new Date(status.date.startDay)).format("DD/MM/YYYY") +
                " à " +
                status?.date.startTime
            }
          />
          <Container
            lable="Fin"
            value={
              status?.date &&
              moment(new Date(status.date.endDay)).format("DD/MM/YYYY") +
                " à " +
                status?.date.endTime
            }
          />
        </section>
      </section>
    </main>
  );
}

export default OneReservation;
