import Filter from "../../types/Filter";
import { ICar } from "../../types/car";
import axiosInstance from "../axios/axiosInstance";
import api from "../endpoint";
import request from "../request";

interface DataAllCarResponse {
  cars: ICar[];
  totalCount: number;
}

export const getCars = () => {
  return request({
    url: "office/getAllCars?documentCount=1&pageNumber=1",
    method: "GET",
  });
};

export const getAllCars = (filtre?: Filter) => {
  let filter: Filter = {
    documentCount: filtre?.documentCount || 100000,
    pageNumber: filtre?.pageNumber || 1,
  };
  return axiosInstance.get<DataAllCarResponse>(api.car.getAllCars, {
    params: {
      limit: filter.documentCount,
      offset: filter.pageNumber,
    },
  });
};

//get one car
export const getOneCar = (carId: any) => {
  return axiosInstance.get<ICar>(api.car.getOneCar, {
    params: {
      carId,
    },
  });
};

//lock car
export const lockCar = (id: any) => {
  return axiosInstance.put(api.car.blockCar, undefined, {
    params: {
      carId: id,
    },
  });
};

//unlock car
export const unlockCar = (id: any) => {
  return axiosInstance.put(api.car.unblockCar, undefined, {
    params: {
      carId: id,
    },
  });
};
