import { Select } from "@mantine/core";
import {
  getBugs,
  getAllCarReports,
  getAllProfileReports,
  markAsResolved,
  getAllBugs,
  getProfileReports,
  getCarReports,
  makeReportAsSeen,
} from "../Services/signalements";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./bugs.scss";
import { IReport } from "../types/report";
import { Error, Loading } from "../Components/LoadingAndError";
import moment from "moment";
import ProfileReportTable from "../Components/Report/ProfileReportTable";
import CarReportTable from "../Components/Report/CarReportTable";
import BugsReportTable from "../Components/Report/BugsReportTable";
import { Id, ToastContainer } from "react-toastify";
import {
  loadingToast,
  successToast,
  toastErrorResponseApi,
} from "../Utils/toast";

function BugsPage() {
  const toastId = useRef<Id | null>(null);

  const [data, setData] = useState<IReport[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const [value, setValue] = useState("Bugs");
  const navigate = useNavigate();

  const getBugsApiCall = async () => {
    setError(false);
    setIsLoading(true);
    try {
      const response = await getAllBugs();
      setData(response.data.reports);
    } catch (error: any) {
      // errorToast(
      //   undefined,
      //   error.response.data.message ||
      //     "Un problème s'est produit, veuillez réessayer"
      // );
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getProfileReportApiCall = async () => {
    setError(false);
    setIsLoading(true);
    try {
      const response = await getProfileReports();
      setData(response.data.reports);
    } catch (error) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getCarReportApiCall = async () => {
    setError(false);
    setIsLoading(true);
    try {
      const response = await getCarReports();
      setData(response.data.reports);
    } catch (error) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getData = () => {
    if (value === "Voiture") {
      getCarReportApiCall();
    } else if (value === "Profile") {
      getProfileReportApiCall();
    } else {
      getBugsApiCall();
    }
  };

  useEffect(() => {
    getData();
  }, [value]);

  const handleSeen = async (id: string) => {
    console.log(id);

    toastId.current = loadingToast(toastId);
    try {
      const reponse = await makeReportAsSeen(id);
      toastId.current = successToast(
        toastId,
        "Le signalement est marqué comme vu"
      );
      getData();
    } catch (error) {
      toastId.current = toastErrorResponseApi(error, toastId);
    }
  };

  return (
    <main className="home">
      <ToastContainer limit={3} />
      <section className="layout">
        <Select
          radius={"md"}
          value={value}
          onChange={(value: string) => {
            setValue(value);
          }}
          searchable
          placeholder="Bugs"
          data={[
            { value: "Bugs", label: "Bugs" },
            { value: "Profile", label: "Profile" },
            { value: "Voiture", label: "Voiture" },
          ]}
        />

        {isLoading && <Loading />}
        {!isLoading && error && <Error />}
        {!isLoading && !error && (
          <>
            <h1>
              Signalements de {value} ({data.length})
            </h1>

            <section className="">
              {value === "Profile" && (
                <ProfileReportTable data={data} onMarkAsSeen={handleSeen} />
              )}
              {value === "Voiture" && (
                <CarReportTable data={data} onMarkAsSeen={handleSeen} />
              )}
              {value === "Bugs" && (
                <BugsReportTable data={data} onMarkAsSeen={handleSeen} />
              )}
            </section>
          </>
        )}
      </section>
    </main>
  );
}

export default BugsPage;
