import { CarsTable } from "../Components/Cars/index";
import { getAllCars, getCars } from "../Services/cars";
import { useQuery } from "react-query";
import { Loading, Error } from "../Components/LoadingAndError/index";

function CarsPage() {
  const { data, error, isLoading } = useQuery(["cars"], () =>
    getAllCars().then((res) => res.data.cars.reverse())
  );

  if (isLoading) return <Loading />;
  if (error) return <Error />;

  return (
    <main className="home">
      <section className="layout">
        <CarsTable data={data && data.length > 0 ? data : []} />
      </section>
    </main>
  );
}

export default CarsPage;
