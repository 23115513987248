import { UserTable } from "../Components/Users/index";
import { getAllUser, getAllUsers } from "../Services/user";
import { useQuery } from "react-query";
import { Loading, Error } from "../Components/LoadingAndError/index";

function UserPage() {
  const { data, error, isLoading } = useQuery(["users"], () =>
    getAllUser().then((res) => res.data.users)
  );

  if (isLoading) return <Loading />;
  if (error) return <Error />;

  return (
    <main className="home">
      <section className="layout">
        <UserTable data={data && data.length > 0 ? data : []} />
      </section>
    </main>
  );
}

export default UserPage;
